import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from 'react-markdown'
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export const FaqPageTemplate = ({ title, heading, content, questions, contentComponent }) => {
  const [selected, setSelected] = useState(-1)

  return (
    <section className="section section--gradient">
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us - Compensation Consultants - Kamsa</title>
          <meta property="og:title" content="About Us - Compensation Consultants - Kamsa" />
          <meta name="description" content="Get to know Kamsa, your partner for managing employee compensation. We combine technology with a human touch." />
        </Helmet>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1 has-text-weight-bold is-bold-light">
                {heading || title}
              </h1>
              <div className="questions-list">
                { questions.map((val, index) =>
                  <div key={index} className="question box">
                    <div className="text">
                      <a
                        className="subtitle has-normal-ws button has-text-left is-primary-invert px-0"
                        style={{ whiteSpace: 'normal' }}
                        onClick={() => setSelected(index === selected ? -1 : index)}
                      >
                        {val.question}
                      </a>
                    </div>
                    <div className={`block content description ${selected === index ? 'expanded' : ''}`}>
                      <ReactMarkdown>{ val.answer }</ReactMarkdown>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FaqPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string,
  content: PropTypes.string,
  questions: PropTypes.array,
  contentComponent: PropTypes.func,
};

const FaqPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FaqPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        questions={post.frontmatter.questions}
      />
    </Layout>
  );
};

FaqPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FaqPage;

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        header
        questions {
          question
          answer
        }
      }
    }
  }
`;